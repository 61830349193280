@import '../../css/variables';

.mazaia__about{
  display: flex;
  margin: 0px auto;
  padding: 33px 0px;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #0A0A0A;
  @media only screen and (max-width: 980px){
    min-height: 50vh;
  }
  &__link{
    color: #fff;
    font-weight: 700;
    font-size: 1em;
    text-decoration: none;
  }
  &__menu{
    width: 100%;
    ul{
      display: flex;
      list-style: none;
      padding: 0px;
      li{
        margin-right: 1em;
        a{
          font-family: 'Safiro','Poppins',sans-serif;
          font-weight: 400;
          font-size: 1em;
          text-transform: none;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  &__container{
    max-width: 94.11%;
    display: flex;
    margin: 0px auto;
    padding: 33px 0px;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    @media only screen and (max-width: 580px){
      max-width: 98%;
    }
  }
  
  &__coluna1{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 90%;
    justify-content: space-evenly;
    flex-direction: column;
    @media only screen and (max-width: 980px){
      width: 15%;
    }
    &::before{
      content: '';
      width: 1px;
      height: 40%;
      background: transparent;
      display: flex;
      margin: 0px auto;
    }
    &::after{
      content: '';
      width: 1px;
      height: 40%;
      background: transparent;
      display: flex;
      margin: 0px auto;
    }
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #fff;
    }
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #fff;
    }
  }
  &__coluna2{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 980px){
      width: 15%;
    }
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #fff;
    }
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #fff;
    }
    svg,svg *{
      fill: #fff;
       
    }
  }
  &__coluna3{
    text-align: left;
    flex-direction: column;
    display: inline-flex;
    width: 70%;
    height: 80%;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    @media only screen and (max-width: 980px){
      width: 65%;
    }
    .mazaia__about__spacer{
      width: 33%;
    }
    h3{
      
      color: #fff;
      text-align: left;
      font-family: Poppins;
      font-size: clamp(50px, 7vw, 140px);
      font-style: normal;
      font-weight: 700;
      line-height: 1; /* 78.014% */
      text-align: left;
    }
    p{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 500;
      font-size: clamp(16px, 2vw, 1.5em);
      color: #fff;
      line-height: 1.5;
    }
  }
}
.mazaia__about__coluna2{
.breadcrumbs{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 40%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media only screen and (max-width: 580px){
    transform: scale(0.8);
  } 
}
}
.mazaia__down{
  &.black{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 40%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media only screen and (max-width: 980px){
    height: 100%;
  }
  .mazaia__down__text{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #fff;
    font-family: 'Safiro','Poppins',sans-serif;
    font-weight: 600;
    font-size: 1em;
    transition: all ease-in-out 300ms;
  }
  }
  &:hover{
    .mazaia__down__text{
      color: $c2;
    }
  }
}