@import '../../css/variables';

.mazaia__portfolio{
  display: flex;
  margin: 0px auto;
  padding: 33px 0px;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: $c4;
  @media only screen and (max-width: 980px){
    height: auto;
    min-height: 0px;
    min-height: 0px;
  }
  &__link{
    color: #000;
    font-weight: 700;
    font-size: 1em;
    text-decoration: none;
  }
  &__menu{
    width: 100%;
    ul{
      display: flex;
      list-style: none;
      padding: 0px;
      li{
        margin-right: 1em;
        a{
          font-family: 'Safiro','Poppins',sans-serif;
          font-weight: 400;
          font-size: 1em;
          text-transform: none;
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
  &__container{
    max-width: 94.11%;
    display: flex;
    margin: 0px auto;
    padding: 33px 0px;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
  }
  
  &__coluna1{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 90%;
    justify-content: space-evenly;
    flex-direction: column;
    @media only screen and (max-width: 980px){
      width: 15%;
      max-width: 100%;
    }
    &::before{
      content: '';
      width: 1px;
      height: 40%;
      background: transparent;
      display: flex;
      margin: 0px auto;
    }
    &::after{
      content: '';
      width: 1px;
      height: 40%;
      background: transparent;
      display: flex;
      margin: 0px auto;
    }
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
  }
  &__coluna2{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 980px){
      width: 15%;
      max-width: 100%;
    }
    &::before{
      content: '';
      width: 1px;
      height: 40%;
      background: transparent;
      display: flex;
      margin: 0px auto;
      @media only screen and (max-width: 980px){
        height: 0px;
      }
    }
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
  }
  &__coluna3{
    text-align: center;
    flex-direction: column;
    display: inline-flex;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 980px){
      width: 65%;
    }
    .mazaia__portfolio__spacer{
      width: 33%;
    }
    h3{
      span{
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 100% */
        text-transform: uppercase;
        text-align: left;
        width: 100%;
        display: flex;
        margin-left: 5px;
      }
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: clamp(40px, 7vw, 140px);
      font-style: normal;
      font-weight: 700;
      line-height: 0.3; /* 78.014% */
      text-align: left;
    }
    h4{
      color: #000;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 100% */
      text-transform: uppercase;
      text-align: center;
      width: 30%;
      margin-top: 70px;
      @media only screen and (max-width: 980px){
        width: 100%;
        max-width: 100%;
        margin: 20px;
      }
    }
  }
}

.mazaia__portfolio__coluna2{
.breadcrumbs{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 40%;
  justify-content: center;
  align-items: end;
  text-decoration: none;
  overflow: hidden;
  @media only screen and (max-width: 980px){
    height: 100%;
  }
  @media only screen and (max-width: 580px){
    transform: scale(0.8);
  }
}
}
.mazaia__down{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media only screen and (max-width: 980px){
    height: 100%;
  }
  @media only screen and (max-width: 980px){
    svg{
      width: 16px;
    }
  }
  &__text{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #000;
    font-family: 'Safiro','Poppins',sans-serif;
    font-weight: 600;
    font-size: 1em;
  }
}