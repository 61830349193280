$c1: #395873;
$c2: #D96704;
$c3: #F2AE72;
$c4: #F2E8DF;
$c5: #26110D;

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-MediumItalic.eot');
  src: url('../../public/fonts/safiro/Safiro-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-MediumItalic.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-MediumItalic.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-MediumItalic.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-MediumItalic.svg#Safiro-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-BoldItalic.eot');
  src: url('../../public/fonts/safiro/Safiro-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-BoldItalic.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-BoldItalic.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-BoldItalic.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-BoldItalic.svg#Safiro-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-Bold.eot');
  src: url('../../public/fonts/safiro/Safiro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-Bold.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-Bold.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-Bold.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-Bold.svg#Safiro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-Medium.eot');
  src: url('../../public/fonts/safiro/Safiro-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-Medium.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-Medium.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-Medium.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-Medium.svg#Safiro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-RegularItalic.eot');
  src: url('../../public/fonts/safiro/Safiro-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-RegularItalic.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-RegularItalic.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-RegularItalic.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-RegularItalic.svg#Safiro-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-Regular.eot');
  src: url('../../public/fonts/safiro/Safiro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-Regular.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-Regular.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-Regular.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-Regular.svg#Safiro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-SemiBold.eot');
  src: url('../../public/fonts/safiro/Safiro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-SemiBold.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-SemiBold.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-SemiBold.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-SemiBold.svg#Safiro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Safiro';
  src: url('../../public/fonts/safiro/Safiro-SemiBoldItalic.eot');
  src: url('../../public/fonts/safiro/Safiro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../public/fonts/safiro/Safiro-SemiBoldItalic.woff2') format('woff2'),
      url('../../public/fonts/safiro/Safiro-SemiBoldItalic.woff') format('woff'),
      url('../../public/fonts/safiro/Safiro-SemiBoldItalic.ttf') format('truetype'),
      url('../../public/fonts/safiro/Safiro-SemiBoldItalic.svg#Safiro-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

