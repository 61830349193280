@import '../../css/variables';

.mazaia__contato{
  display: flex;
  margin: 0px auto;
  padding: 33px 0px;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: $c4;
  @media only screen and (max-width: 980px){
    min-height: 0px;
    padding-top: 90px;
    height: auto;
  }
  &__buttons{
    display: flex;
    padding: 30px 0px;
    list-style: none;
    @media only screen and (max-width: 980px){
      flex-wrap: wrap;
      justify-content: center;
    }
    li{
      margin: 0.5rem;
      a{
        text-decoration: none;
        text-transform: none;
        font-family: 'Safiro','Poppins',sans-serif;
        font-weight: 500;
        font-size: 1em;
        border: 2px solid #000;
        min-width: 200px;
        display: flex;
        text-align: center;
        justify-content: center;
        color: #000;
        border-radius: 30px;
        padding: .2rem 1rem;
        transition: all ease-in-out 300ms;
        &:hover{
          background: $c2;
          color: #FFF;
          border-color: $c2;
        }
      }
    }
  }
  &__menu{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    @media only screen and (max-width: 980px){
      flex-wrap: wrap;
      margin-top: 40px;
      justify-content: center;

    }
    &__item{
      max-width: 290px;
      @media only screen and (max-width: 980px){
        margin: 20px 0px 0px;
      }
      @media only screen and (max-width: 580px){
        margin: 20px 0px 0px;
        width: 100%;
      }
      &:last-child{
        text-align: right;
      }
      p{
        font-family: 'Safiro','Poppins',sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
        @media only screen and (max-width: 980px){
          text-align: center;
        }
        a{
          color: #000;
          transition: all ease-in-out 300ms;
          &:hover{
            color: $c2;
          }
        }
        
      }
      a{
        text-decoration: none;
        color: #000;
        transition: all ease-in-out 300ms;
          &:hover{
            color: $c2;
          }
      }
    }
    ul{
      display: flex;
      list-style: none;
      padding: 0px;
      @media only screen and (max-width: 980px){
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        padding: 0px 30px;
      }
      @media only screen and (max-width: 580px){
        justify-content: center;
      }
      li{
        margin-right: 1em;
        a{
          font-family: 'Safiro','Poppins',sans-serif;
          font-weight: 400;
          font-size: 1em;
          text-transform: none;
          text-decoration: none;
          color: #000;
           
        transition: all ease-in-out 300ms;
          &:hover{
            color: $c2;
          }
        }
      }
    }
  }
  &__container{
    max-width: 94.11%;
    display: flex;
    margin: 0px auto;
    padding: 45px 0px;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    flex-wrap: wrap;
    @media only screen and (max-width: 580px){
      min-height: 0px;
      height: auto;
      padding: 33px 0px 0px;
      max-width: 98%;
    }
  }
  
  &__coluna1{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 90%;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    @media only screen and (max-width: 980px){
      width: 15%;
    }
    
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
  }
  &__coluna2{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    @media only screen and (max-width: 980px){
      width: 15%;
    }
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
    }
  }
  &__coluna3{
    text-align: center;
    flex-direction: column;
    display: inline-flex;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    @media only screen and (max-width: 980px){
      width: 60%;
    }
    &__texto{
      max-width: 1105px;
      display: flex;
      flex-wrap: wrap;
      margin: 0px auto 40px;
      justify-content: center;
      align-items: start;
      h3{
        width: auto;
        max-width: 100%;
        width: 100%;
        font-family: 'Safiro', 'Poppins' ,sans-serif;
      }
      div{
        width: 10%;
        font-family: 'Safiro', 'Poppins' ,sans-serif;
        @media only screen and (max-width: 980px){
          width: 100%;
        }
      }
      h5{
        width: 20%;
        margin-top: 35px;
        font-family: 'Safiro', 'Poppins' ,sans-serif;
        @media only screen and (max-width: 980px){
          width: 100%;
          margin: 0px;
        }
      }
      h4{
        width: 40%;
        font-family: 'Safiro', 'Poppins' ,sans-serif;
        @media only screen and (max-width: 980px){
          width: 100%;
        }
      }
    }
    h4{
      color: #000;
      font-family: 'Safiro', 'Poppins' ,sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 100% */
      text-transform: uppercase;
      width: 75%;
      text-align: left;
      @media only screen and (max-width: 980px){
        width: 100%;
        text-align: center;
      }
    }
    h5{
      color: #000;
      font-family: 'Safiro', 'Poppins' ,sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 100% */
      text-transform: uppercase;
      text-align: left;
      @media only screen and (max-width: 980px){
        text-align: center;
      }
    }
    h3{
      color: #000;
      text-align: center;
      font-family: 'Safiro', 'Poppins' ,sans-serif;
      font-size: clamp(45px, 7vw, 140px);
      font-style: normal;
      font-weight: 700;
      line-height: 0.8; /* 78.014% */
      @media only screen and (max-width: 980px){
        text-align: left;
        font-size: clamp(45px, 8vw, 140px);
      }
    }
    p{
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: clamp(16px, 4vw, 20px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 554px;
      margin: 0px auto;
      @media only screen and (max-width: 580px){
        font-size: clamp(16px, 4vw, 16px);
      }
      &.texto__menor{
        text-transform: lowercase;
        @media only screen and (max-width: 580px){
          font-size: clamp(16px, 4vw, 16px);
        }
      }
    }
  }
}
.breadcrumbs__fim{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 40%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @media only screen and (max-width: 580px){
    transform: scale(0.8);
  }
}
.mazaia__down__fim{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 40%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &__arrow{
    transform: rotate(180deg);
    @media only screen and (max-width: 980px){
      svg{
        width: 16px;
      }
    }
  }
  &__text{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #000;
    font-family: 'Safiro','Poppins',sans-serif;
    font-weight: 600;
    font-size: clamp(16px, 7vw, 16px);
    transition: all ease-in-out 300ms;
  }
  &:hover{
    .mazaia__down__fim__text{
      color: $c2;
    }
    .mazaia__down__fim__arrow svg *{
      fill: $c2;
    }
  }
}