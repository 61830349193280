@import '../../css/variables';

.mazaia__banner{
  display: flex;
  margin: 0px auto;
  padding: 33px 0px;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  width: 100%;
  background: $c4;
  @media only screen and (max-width: 980px){
    height: 65vh;
  }
  &__menu{
    width: 100%;
    ul{
      display: flex;
      list-style: none;
      padding: 0px;
      @media only screen and (max-width: 980px){
        justify-content: start;
        padding: 0px 20px;
      }
      li{
        margin-right: 1em;
        @media only screen and (max-width: 980px){
          margin: 0px .5em;
        }
        a{
          font-family: 'Safiro','Poppins',sans-serif;
          font-weight: 400;
          font-size: 1em;
          text-transform: none;
          text-decoration: none;
          color: #000;
          transition: all ease-in-out 0.3s;
          &:hover{
            color: $c2;
          }
        }
      }
    }
  }
  &__container{
    max-width: 95%;
    display: flex;
    margin: 0px auto;
    padding: 33px 0px;
    justify-content: space-between;
    align-items: center;
    height: 90%;
    width: 100%;
    flex-wrap: wrap;
    @media only screen and (max-width: 580px){
      max-width: 95%;
    }
  }
  
  &__coluna1{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 80%;
    justify-content: space-evenly;
    flex-direction: column;
    @media only screen and (max-width: 980px){
      width: 15%;
    }
 
    &::before{
      content: '';
      width: 1px;
      height: 40%;
      background: #000;
      display: flex;
      margin: 0px auto;
      @media only screen and (max-width: 980px){
        height: 30%;
      }
    }
    &::after{
      content: '';
      width: 1px;
      height: 40%;
      background: #000;
      display: flex;
      margin: 0px auto;
      @media only screen and (max-width: 980px){
        height: 30%;
      }
    }
    h5{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: 0.95em;
      color: #000;
      @media only screen and (max-width: 980px){
        font-size: 0.65em;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: -1px;
      }
    }
    
  }
  &__coluna2{
    text-align: center;
    max-width: 120px;
    width: 10%;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 980px){
      width: 15%;
    }
 
    &::before{
      content: '';
      width: 1px;
      height: 40%;
      background: transparent;
      display: flex;
      margin: 0px auto;
    }
    
    h6{
      font-family: 'Safiro','Poppins',sans-serif;
      font-weight: 700;
      font-size: clamp(16px, 0.95vw, 0.95em);
      color: #000;
      @media only screen and (max-width: 980px){
        font-size: 0.65em;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: -1px;
      }
    }
  }
  &__coluna3{
    text-align: center;
    flex-direction: column;
    display: inline-flex;
    width: 80%;
    height: 80%;
    justify-content: space-evenly;
    align-items: center;
    @media only screen and (max-width: 980px){
      height: 40%;
      width: 65%;
    }
 
    h4{
      color: #000;
      font-family: 'Safiro','Poppins',sans-serif;
      font-size: clamp(16px, 4vw, 20px);
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 100% */
      text-transform: uppercase;
      overflow: hidden;
      @media only screen and (max-width: 980px){
        font-size: clamp(14px, 2.5vw, 20px);
      }
     
    }
    h3{
      color: #000;
      text-align: center;
      font-family: 'Safiro','Poppins',sans-serif;
      font-size: clamp(30px, 7vw, 140px);
      font-style: normal;
      font-weight: 700;
      line-height: 0.8; /* 78.014% */
      transition: all ease-in-out 200ms 0ms;
      overflow: hidden;
      @media only screen and (max-width: 980px){
        font-size: clamp(20px, 6.5vw, 30px);
      }
      @media only screen and (max-width: 580px){
        font-size: clamp(30px, 8vw, 35px);
        margin: 10px 0px;
      }
    }
    p{
      color: #000;
      text-align: center;
      font-family: 'Safiro','Poppins',sans-serif;
      font-size: clamp(16px, 4vw, 20px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: 454px;
      margin: 0px auto;
      @media only screen and (max-width: 980px){
        font-size: clamp(13px, 2vw, 19px);
      }
    }
  }
}
.mazaia__banner__coluna2{
.breadcrumbs{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: auto;
  justify-content: center;
  align-items: end;
  text-decoration: none;
  overflow: hidden;
  @media only screen and (max-width: 580px){
    transform: scale(0.8);
  }
}
.mazaia__down{
  display: flex;
  margin: 0px auto;
  transform: translateX(0px);
  height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all ease-in-out 300ms;
  &__text{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    color: #000;
    font-family: 'Safiro','Poppins',sans-serif;
    font-weight: 600;
    font-size: 1em;
    transition: all ease-in-out 300ms;
  }
  &__arrow{
    svg *{
      transition: all ease-in-out 100ms 300ms;
    }
  }
  &:hover{
    .mazaia__down__text{
      color: $c2;
    }
    .mazaia__down__arrow svg *{
      fill: $c2;
    }
  }
}
}